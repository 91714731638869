import React, {Component} from 'react'

class CashOffer extends Component {
    render(){
        return(
            <div><h1> Get Multiple Offers For Your House Today!</h1></div>
        )
    }
}

export default CashOffer