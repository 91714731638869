import React, {Component} from 'react'



class Listings extends Component {
    render(){
        return(
            <div>Investor Listings</div>
        )
    }
}

export default Listings