import React, {Component} from 'react'



class SellerFinance extends Component {
    render(){
        return(
            <div>Seller Finance</div>
        )
    }
}

export default SellerFinance